// third party imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// internal rebet imports
import "./stylesPrizeRedemptions.css";
import createPrivateAdminPanelInstance from "../../../../api/PrivateAdminPanelInstance";
import { handleExpiredTokenError } from "../../../../utils/ErrorUtils";
import { API_ROUTES } from "../../../../api/ApiRoutes";
import { TIME_FRAMES } from "../../../../utils/Constants";

// component imports
import RebetLoader from "../../../CommonComponents/RebetLoader/RebetLoader";
import DropDown from "../../../CommonComponents/DropDown/DropDown";
import SortByDate from "../../../../utils/SortByDate";
import { formatDatesArray } from "../../../../utils/DateFormatter";
import { formatNumber } from "chart.js/helpers";

// asset imports

const PrizeRedemptions = () => {
  // non-state variables
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  const PrivateAdminPanelInstance = createPrivateAdminPanelInstance(
    token?.IdToken
  );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  // state variables
  const [apiIsRunning, setApiIsRunning] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(
    TIME_FRAMES?.MONTH
  );

  // use effects
  useEffect(() => {
    getPurchases(selectedTimeFrame);
  }, [selectedTimeFrame]);

  // api functions
  const getPurchases = async (time_period) => {
    setApiIsRunning(true);

    const params = {
      group_by: time_period,
    };

    try {
      const response = await PrivateAdminPanelInstance?.post(
        API_ROUTES?.GET_TOTAL_PRIZE_REDEMPTIONS,
        params
      );

      setGraphData(response?.data?.data);
      setApiIsRunning(false);
    } catch (error) {
      setApiIsRunning(false);
      console.log("error fetching purchase data", error?.response);
    }
  };

  // helper functions
  const handleSetTimeFrame = (value) => {
    setSelectedTimeFrame(value);
  };

  const sortedByDateData = SortByDate(graphData);
  const formatedDate = formatDatesArray(
    Object.keys(sortedByDateData),
    selectedTimeFrame
  );

  const dataValues = Object.values(sortedByDateData).map((value) =>
    parseFloat(value?.amount)
  );

  const data = {
    labels: formatedDate, // X-axis labels from the keys (dates)
    datasets: [
      {
        label: "PrizeRedemptions",
        data: dataValues, // Y-axis values from the data
        backgroundColor: "#E6533C",
        borderRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        intersect: false,
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return `${context.dataset.label}: ${formatNumber(
              value,
              "en-US",
              "decimal",
              2
            )}\n`;
          },
        },
        backgroundColor: "#25252F",
        titleFont: {
          family: "Poppins-regular",
          size: 16,
          weight: "bold",
        },
        bodyFont: {
          family: "Poppins-regular",
          size: 14,
        },
        padding: 20,
        displayColors: true,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#FFF", // Set the color of the y-axis labels
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#FFF", // Set the color of the y-axis labels
        },
        afterDataLimits: (axis) => {
          axis.max = axis.max * 1.1; // Increase the max value by 10% to make space for labels
        },
      },
    },
  };

  return (
    <div className="wagers-by-day-graph">
      <div className="wagers-by-day-graph-header-row">
        <div className="left-half-graph-header">
          <div className="wagers-by-day-graph-header-label">
            Total Prize Redemptions
          </div>
        </div>
        <div className="right-half-graph-header">
          <DropDown
            drop_down_options={TIME_FRAMES}
            selected_value={selectedTimeFrame}
            set_selected_value={handleSetTimeFrame}
          />
        </div>
      </div>
      <div className="wagers-graph-container">
        {apiIsRunning ? (
          <RebetLoader height={"150px"} width={"150px"} />
        ) : graphData ? (
          <Bar data={data} options={options} />
        ) : (
          <div>No data available</div>
        )}
      </div>
    </div>
  );
};

export default PrizeRedemptions;
